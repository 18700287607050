import React, { Fragment, useEffect, useState } from "react";
import Image from "next/image";
import p7 from "../../../public/img/features-12.png";
import p8 from "../../../public/img/features-13.png";
import p9 from "../../../public/img/features-10.png";
import p11 from "../../../public/img/features-11.png";
import heart from "../../../public/img/heart.png";
import cart from "../../assets/images/cart.png";
import p10 from "../../../public/img/p10.png";
import arrow from "../../assets/images/arrow.png";
import styles from "@/styles/new_arrivals.module.scss";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { homeLinks } from "@/data/home";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import endpoints from "@/api/endpoints";
import httpClient from "@/api/http-client";
import ProductCard from "../product-card/product-card";
SwiperCore.use([Navigation, Pagination]);

const New_Arrivals = () => {
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    newArrival();
  }, []);

  const newArrival = async () => {
    try {
      const { data } = await httpClient.get(endpoints.home.newArrival());
      setData(data);
    } catch (error) {}
  };

  if (!data.length) {
    return <></>;
  }

  return (
    <Fragment>
      <section className={styles.new_arrivals}>
        <div className="container">
          <div className={styles.new_arrivalsheader}>
            <div className="row">
              <div className="col-md-6">
                <h5>{homeLinks.newArival.title}</h5>
              </div>
              <div className="col-md-6">
                <Link href="/new-arrivals" passHref>
                  <div className={styles.btn_get_started}>SEE ALL Products</div>
                </Link>
              </div>
            </div>
            <fw-embed-feed
              channel="senco"
              playlist="5aKDPg"
              mode="row"
              open_in="default"
              max_videos="0"
              placement="middle"
              player_placement="bottom-right"
              branding="false"
            ></fw-embed-feed>
            <div className={styles.new_arrivals_main}>
              <div className="row">
                <Swiper
                  className="swiper-navigation-black"
                  navigation={true}
                  spaceBetween={20}
                  slidesPerView={1}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    1024: {
                      slidesPerView: 4,
                    },
                    1200: {
                      slidesPerView: 4,
                    },
                  }}
                >
                  {data.slice(0, 20).map((product: any) => (
                    <SwiperSlide key={product.id}>
                      <ProductCard product={product} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default New_Arrivals;
